<template>
  <div class="row text-center">
    <GmapMap
      ref="pulseMapRef"
      class="pulse-tours-map"
      :zoom="5"
      :center="mapDataRef.center"
      :options="defaultMapOptions"
      map-type-id="roadmap"
    />
  </div>
</template>

<script>
import mapStyles from '@/assets/googleMapStyles.json';
import { ref } from '@vue/composition-api/dist/vue-composition-api';

const defaultMapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUI: false,
  styles: mapStyles,
};
/* eslint-disable no-undef */
export default {
   setup(props, context) {
     const { market_name } = { ...context.root.$route.params };
     const mapDataRef = ref({
       center: { lat: 42.861, lng: -93.37 },
     });
    initMap();
    return {
      google,
      getMarketBounds,
      defaultMapOptions,
      mapDataRef,
    };
     async function initMap() {
       await window.vueGoogleMapsInit(google);
       const market = await context.root.$store.dispatch('Market/getMarket', market_name);
       context.refs.pulseMapRef.$mapPromise.then((map) => {
         const polygonBounds = getMarketBounds(market);
         map.fitBounds(polygonBounds);
       });
       context.root.$nextTick(() => {
         context.refs.pulseMapRef.$mapPromise.then((map) => {
           market.features = map.data.addGeoJson(market.feature_collection_multi_polygon);
           map.data.setStyle({
             fillOpacity: 0.1,
             strokeWeight: 1,
           });
         });
       });
     }

     function getMarketBounds(market) {
       const polygon = market.area.coordinates[0][0];
       const polygonBounds = new google.maps.LatLngBounds();
       const polygonCoords = [];

       polygon.forEach((polygon) => {
         polygonCoords.push(new google.maps.LatLng(polygon[1], polygon[0]));
       });

       polygonCoords.forEach((coord) => {
         polygonBounds.extend(coord);
       });

       return polygonBounds;
     }
  },
};
</script>

<style lang="scss">
.pulse-tours-map {
  width: 100%;
  height: 100vh;
  .gm-style .gm-style-iw-c{
    border-radius: 4px;
    padding: 0;
    width: 246px;
    box-shadow: none;
    .gm-ui-hover-effect {
      visibility: hidden;
    }
  }
  .gm-style-iw-d {
    overflow-x: hidden !important;
    overflow-y: auto   !important;
  }
}
</style>
